import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {PublicOfferBundleData, PublicOfferPagePreviewData} from "../../generated/cms/data";
import {MediaService} from "../../media.service";
import {PublicBundleResource} from "../../generated/cms/resources";
import {Subject} from "rxjs";

@Component({
  selector: 'app-bundle-caroussel',
  templateUrl: './bundle-caroussel.component.html',
  styleUrls: ['./bundle-caroussel.component.scss']
})
export class BundleCarousselComponent implements OnInit {

  @Input() bundle: PublicOfferBundleData

  @ViewChild("caroussel", { static: true }) caroussel: ElementRef;
  @ViewChild("carousselContent") carousselContent: ElementRef;

  pages: Subject<PublicOfferPagePreviewData>[] = [];

  slickConfig = {
    infinite: false,
    variableWidth: true,
    mobileFirst: true,
  }

  isMobile = true;
  loading  = new Subject<boolean>();

  constructor(private router: Router, private mediaService: MediaService, private bundleRessource: PublicBundleResource) {
  }

  ngOnInit() {
    //To render template elements
    for(let page of this.bundle.offerPageIds){
      this.pages.push(new Subject<PublicOfferPagePreviewData>())
    }

    this.isMobile = this.mediaService.isMobile()
    this.loadWithPreviews();
  }


  loadWithPreviews() {
    this.loading.next(true);

      this.bundleRessource.getBundleWithPreviews(this.bundle.routingUrl).then(res => {
        for(let page of  res.offerPagePreviews){
          this.pages[res.offerPagePreviews.indexOf(page)].next(page)
        }
        this.loading.next(false)
      })
  }

  currentScrollOffset = 0;

  leftScroll() {
    this.currentScrollOffset = Math.min(0, this.currentScrollOffset + 200);
    this.checkArrows();
  }

  rightScroll() {
    var maxScroll = this.getMaxOffset();
    this.currentScrollOffset = Math.max(-maxScroll, this.currentScrollOffset - 200);
    this.checkArrows();
  }

  getMaxOffset() {
    var width = this.caroussel.nativeElement.clientWidth;
    var contentWidth = this.carousselContent.nativeElement.clientWidth;
    return contentWidth - width;
  }

  checkArrows() {
    this.checkLeftArrow();
    this.checkRightArrow()
  }

  showLeftArrow = false;

  checkLeftArrow() {
    this.showLeftArrow = -this.currentScrollOffset > 0;
  }

  showRightArrow = true;

  //This shows the arrow if too few offers are available , but this would look stupid in any case
  instagram: any;
  checkRightArrow() {
    this.showRightArrow = -this.currentScrollOffset < this.getMaxOffset();
  }

  mobileScroll($event) {
    console.log($event)
  }
}
