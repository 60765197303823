import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {EnterPromotionContactData} from '../../../../generated/cronos/data';
import {UtilsService} from '../../../../utils.service';
import {LocalStorageService} from '../../../../local-storage.service';
import {PromotionApiResourceV2, PublicStructureResourceV2} from '../../../../generated/cronos/resources';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {StandardCheckoutService} from '../../standard-checkout.service';
import {StandardCheckoutS1ProcessService} from '../standard-checkout-s1-process.service';

@Component({
  selector: 'app-sta-checkout-s1-user',
  templateUrl: './sta-checkout-s1-user.component.html',
  styleUrls: ['./sta-checkout-s1-user.component.scss']
})
export class StaCheckoutS1UserComponent implements OnInit {



  contactForm: FormGroup;

  page: PublicFormatPageData;
  user: EnterPromotionContactData

  constructor(private fb: FormBuilder,
              private utilsService: UtilsService,
              public localStorageService: LocalStorageService,
              private publicStructureResource: PublicStructureResourceV2,
              private promotionApiResourceV2: PromotionApiResourceV2,
              public globalCheckoutService: GlobalCheckoutService,
              public standardCheckoutService: StandardCheckoutService,
              private router: Router,
              private standard1TrackingService: StandardCheckoutS1ProcessService,
              private route: ActivatedRoute
  ) {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      acceptPrivacy: ['', Validators.requiredTrue],
      acceptAdditionalTerms: [''],
      studyEndYear: [''],
      phoneNumber: [null, [this.utilsService.validatePhoneNumber_split1(), Validators.required]]
    });

  }

  checkoutAttempted = new BehaviorSubject<boolean>(false);
  additionalTermsRequired = false;
  ngOnInit() {
    this.standard1TrackingService.reportTrackingEvent('ContactDataReached');

    this.page = this.globalCheckoutService.getCurrentPage()
    this.user = this.globalCheckoutService.getCurrentUser()
    this.globalCheckoutService.disableForwarding()

    this.promotionApiResourceV2.getPromotionFormat(this.page.cronosFormatId).then(result => {

      this.additionalTermsRequired = result.showConditionsOfParticipation
    });
    window.scrollTo(0, 0);
    //If competition we need to display terms
    if (this.additionalTermsRequired) {
      this.contactForm.controls.acceptAdditionalTerms.setValidators([Validators.requiredTrue])
    }

    //Init the form with given contactdata
    this.contactForm.controls['firstName'].setValue(this.user.firstName);
    this.contactForm.controls['lastName'].setValue(this.user.lastName);
    this.contactForm.controls['email'].setValue(this.user.email);
    this.contactForm.controls['phoneNumber'].setValue(this.user.phoneNumber);
    this.contactForm.controls['studyEndYear'].setValue(this.user.studyEndYear);
  }

  checkingOut = false;
  attemptCheckout(){
    this.checkoutAttempted.next(true)
    if(!this.contactForm.valid)return
    this.globalCheckoutService.fillUserData(<EnterPromotionContactData>{
      firstName: this.contactForm.controls['firstName'].value,
      lastName: this.contactForm.controls['lastName'].value,
      email: this.contactForm.controls['email'].value,
      phoneNumber: this.contactForm.controls['phoneNumber'].value,
      studyEndYear: this.contactForm.controls['studyEndYear'].value,
      privacyConsentGiven: true //consent is mandatory input field
    })
    this.checkingOut = true;
    this.standard1TrackingService.reportTrackingEvent('ContactDataCompleted');
    this.standardCheckoutService.completeCheckout(this.standard1TrackingService.splitIdentifier);
  }

  back(){
    this.router.navigate([this.standard1TrackingService.prepareSplitRoutingUrl(this.route.parent, 'checkout/standard/s1/location')]);
  }
}
