import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AECDiscCheckoutAEC1ProcessService} from "../aecdisc-checkout-aec1-process.service";
import {GlobalCheckoutService} from "../../../global-checkout.service";

@Component({
  selector: 'app-aec1-checkout-entry',
  templateUrl: './aec1-checkout-entry.component.html',
  styleUrls: ['./aec1-checkout-entry.component.scss']
})
export class AEC1CheckoutEntryComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private aec1ProcessService: AECDiscCheckoutAEC1ProcessService,
    private globalCheckoutService: GlobalCheckoutService
  ) { }

  ngOnInit(): void {
    //route to first step and set forwarding to enabled for initial forwarding
    this.router.navigate([this.aec1ProcessService.prepareSplitRoutingUrl(this.route, 'checkout/aec/aec1/location')]);
    this.globalCheckoutService.enableForwarding()
  }

}
