import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SeminarCheckoutS6ProcessService} from "../seminar-checkout-s6-process.service";
import {GlobalCheckoutService} from "../../../global-checkout.service";

@Component({
  selector: 'app-seminar-checkout-s6-entry',
  templateUrl: './seminar-checkout-s6-entry.component.html',
  styleUrls: ['./seminar-checkout-s6-entry.component.scss']
})
export class SeminarCheckoutS6EntryComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,
              private s6ProcessService: SeminarCheckoutS6ProcessService,
              private globalCheckoutService: GlobalCheckoutService) { }

  ngOnInit(): void {
    //route to first step and set forwarding to enabled for initial forwarding
    this.router.navigate([this.s6ProcessService.prepareSplitRoutingUrl(this.route, '/checkout/seminars/s6/location')]);
    this.globalCheckoutService.enableForwarding()
  }

}
