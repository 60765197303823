import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GlobalCheckoutService} from "../../../global-checkout.service";

@Component({
  selector: 'app-seminar-checkout-s6-main',
  templateUrl: './seminar-checkout-s6-main.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./seminar-checkout-s6-main.component.scss']
})
export class SeminarCheckoutS6MainComponent implements OnInit, OnDestroy {

  constructor(public globalCheckoutService: GlobalCheckoutService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    //Forewarding will get disabled once a step is reached, that requires input. However, if you just leave the checkout, the forewarding should be enabled again for the next time
    this.globalCheckoutService.enableForwarding()
  }

}
