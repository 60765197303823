import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  constructor(private datePipe: DatePipe) { }

  isWithinNextFullYear(fullYear: number): boolean {
    return fullYear <= new Date().getFullYear() + 1
  }

  getDate(date: Date): string {
    return this.datePipe.transform(date, 'dd.MM.yyyy', 'Europe/Berlin', 'de-DE')
  }

  getWeekday(date: Date): string {
    return this.datePipe.transform(date, 'EEEE', 'Europe/Berlin', 'de-DE')
  }

  getTime(date: Date): string {
    return this.datePipe.transform(date, 'HH:mm:ss', 'Europe/Berlin', 'de-DE')
  }

}

