import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SeminarCheckoutS5ProcessService} from "../seminar-checkout-s5-process.service";
import {GlobalCheckoutService} from "../../../global-checkout.service";

@Component({
  selector: 'app-seminar-checkout-s5-entry',
  templateUrl: './seminar-checkout-s5-entry.component.html',
  styleUrls: ['./seminar-checkout-s5-entry.component.scss']
})
export class SeminarCheckoutS5EntryComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,
              private s5ProcessService: SeminarCheckoutS5ProcessService,
              private globalCheckoutService: GlobalCheckoutService) {
  }

  ngOnInit(): void {
    //route to first step and set forwarding to enabled for initial forwarding
    this.router.navigate([this.s5ProcessService.prepareSplitRoutingUrl(this.route, '/checkout/seminars/s5/location')]);
    this.globalCheckoutService.enableForwarding()
  }

}
