import {Component, OnInit} from '@angular/core';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {SeminarCheckoutService} from '../../../seminar-checkout/seminar-checkout.service';
import {StandardCheckoutS1ProcessService} from '../standard-checkout-s1-process.service';

@Component({
  selector: 'app-sta-checkout-s1-location',
  templateUrl: './sta-checkout-s1-location.component.html',
  styleUrls: ['./sta-checkout-s1-location.component.scss']
})
export class StaCheckoutS1LocationComponent implements OnInit {


  page: PublicFormatPageData;

  constructor(private router: Router,
              public globalCheckoutService: GlobalCheckoutService,
              private seminarCheckoutService: SeminarCheckoutService,
              private standard1ProcessService: StandardCheckoutS1ProcessService,
              private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    if (this.checkForForewarding()) {
      this.reportReached(true);
      this.next(true);
      return;
    }
    this.reportReached(false);

    window.scrollTo(0, 0);
    this.globalCheckoutService.disableForwarding()
  }

  reportReached(skip: boolean) {
    this.standard1ProcessService.reportTrackingEvent('LocationReached', {'skip': JSON.stringify(skip)});
  }


  checkForForewarding() {
    if (!this.globalCheckoutService.forwardingEnabled) {
      return false;
    }
    //location has already been selected and stored, possibly in a prior checkout
    if (this.globalCheckoutService.getCurrentUser() && this.globalCheckoutService.getCurrentUser().locationId != null) {
      return true;
    }
    return false;
  }

  next(skip: boolean = false) {
    this.standard1ProcessService.reportTrackingEvent('LocationSelected', {'skip': JSON.stringify(skip)});
    this.router.navigate([this.standard1ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/standard/s1/user')]);
  }
}
