import {Component, OnInit} from '@angular/core';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SeminarCheckoutService} from '../../seminar-checkout.service';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {PublicStructureResourceV2} from '../../../../generated/cronos/resources';
import {PublicLocationData, PublicMinimalCityData} from '../../../../generated/cronos/data';
import {BehaviorSubject} from 'rxjs';
import {InternalCookieService} from '../../../../internal-cookie.service';
import {LocalStorageService} from '../../../../local-storage.service';
import {SeminarCheckoutS6ProcessService} from "../seminar-checkout-s6-process.service";

@Component({
  selector: 'app-seminar-checkout-s6-location',
  templateUrl: './seminar-checkout-s6-location.component.html',
  styleUrls: ['./seminar-checkout-s6-location.component.scss']
})
export class SeminarCheckoutS6LocationComponent implements OnInit {
  page: PublicFormatPageData;

  evaluatingIp = true

  zipCodeControl = new FormControl('', [Validators.required, this.validateZipCode()])
  zipCodeFound = false
  zipCodeSearched = false
  searchingZipCode = false

  matchingCities: PublicMinimalCityData[] = []
  zipCode: string = ""

  zipCodeSubmissionAttempted = new BehaviorSubject<boolean>(true)

  constructor(private router: Router,
              public globalCheckoutService: GlobalCheckoutService,
              private seminarCheckoutService: SeminarCheckoutService,
              private route: ActivatedRoute,
              private s6ProcessService: SeminarCheckoutS6ProcessService,
              private cronosStructureApi: PublicStructureResourceV2,
              private internalCookieService: InternalCookieService,
              private localStorageService: LocalStorageService
  ) {

  }

  ngOnInit() {
    //This is in case of backrouting. The step was virtually non-existant for the user so when we are moving back and we retreived the IP before, we directly route back.
    //this is hacky af and should be changed if this split versio continues to exist.
    if (this.s6ProcessService.ipRetrievedSuccessfully && this.s6ProcessService.inCheckout) {
      this.s6ProcessService.inCheckout = false
      this.globalCheckoutService.returnToOfferPage()
      return
    }
    this.s6ProcessService.inCheckout = true
    this.zipCodeControl.valueChanges.subscribe(code => this.evaluateZipCode(code))

    if (this.checkForForewarding()) {
      this.reportReached(true);
      this.next(true);
      return;
    }

    this.reportReached(false);
    window.scrollTo(0, 0);
    this.globalCheckoutService.disableForwarding()
    if (this.internalCookieService.getGDPRConsent()?.consent_ipApi) {
      this.getIPInfo()
    } else {
      this.handleFailedIp("missing consent in cookies")
    }
  }

  getIPInfo() {
    this.cronosStructureApi.getNearestLocationByIpAddress().then(res => {
      if (res.advertisementLocation != null) {
        this.saveIpLocationAndContinue(res.advertisementLocation)
      } else {
        this.handleFailedIp(res.statusMessage)
      }
    })
  }

  saveIpLocationAndContinue(res: PublicLocationData) {
    this.s6ProcessService.ipRetrievedSuccessfully = true
    this.globalCheckoutService.updateLocationOnUser(res)
    this.next(false)
  }

  /**
   * Checks if zip code follows the form (regex) of German zip codes and prevents input beyond 5 numbers.
   * If zip code conforms the database is queried for its existence
   */
  evaluateZipCode(code: string) {
    if (!this.zipCodeControl.hasError('notValidZipCode')) {
      this.findZipCodeInDatabase(this.zipCodeControl.value)
    } else {
      this.zipCodeSearched = false
      if (this.zipCodeControl.getError('notValidZipCode').exceedsLength) {
        let validCode = code.slice(0, -1)
        this.zipCodeControl.setValue(validCode)
      }
    }
  }

  findZipCodeInDatabase(zipCode: string) {
    this.searchingZipCode = true
    this.zipCodeControl.markAsTouched()
    this.cronosStructureApi.doesZipCodeExist({zipCode: zipCode}).then(res => {
      if (res.value) {
        this.zipCodeFound = true
        this.zipCodeControl.updateValueAndValidity({emitEvent: false})
        this.zipCode = this.zipCodeControl.value
        this.matchingCities = []
      } else {
        this.zipCodeFound = false
        this.zipCode = ""
      }
      this.searchingZipCode = false
      this.zipCodeSearched = true
    })
  }


  /**
   * Validates the input for German zip codes (accepts inclusively from 01001 to 99998)
   */
  validateZipCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const zipCodeRegEx: RegExp = new RegExp("^(?!01000|99999)(0[1-9]\\d{3}|[1-9]\\d{4})$")
      if (!control.value) {
        return null
      }
      let isZipCode = zipCodeRegEx.test(control.value)
      let isTooLong = control.value.length > 5
      return isZipCode ? null : {
        notValidZipCode: {
          value: control.value,
          name: "input is not a valid German zip code",
          exceedsLength: isTooLong
        }
      }
    }
  }

  saveZipCodeAndContinue() {
    this.zipCodeSubmissionAttempted.next(true)
    if (!this.zipCodeControl.valid || !this.zipCodeFound) return
    this.cronosStructureApi.getNearestLocationFromZipCode({zipCode: this.zipCode}).then(res => {
      this.globalCheckoutService.updateZipCodeOnUser(this.zipCode)
      this.globalCheckoutService.updateLocationOnUser(res)
      this.next(false)
    })
  }

  continueWithNearestLocationToCity(cityId: number) {
    this.cronosStructureApi.getNearestLocationFromCityId({cityId: cityId}).then(res => {
      this.globalCheckoutService.updateLocationOnUser(res)
      this.next(false)
    })
  }

  reportReached(skip: boolean) {
    this.s6ProcessService.reportTrackingEvent('LocationReached', {'skip': JSON.stringify(skip)});
  }


  checkForForewarding() {
    if (!this.globalCheckoutService.forwardingEnabled) {
      return false;
    }
    //use case comes from a preselected seminar id that was passed via url parameter
    if (this.seminarCheckoutService.getSeminar()?.id) {
      return true;
    }
    //location has already been selected and stored, possibly in a prior checkout
    if (this.globalCheckoutService.getCurrentUser() && this.globalCheckoutService.getCurrentUser().locationId != null) {
      return true;
    }
    return false;
  }

  next(foreward: boolean) {
    if (!foreward) {
      this.seminarCheckoutService.clearSelectedSeminar();
    }
    this.s6ProcessService.reportTrackingEvent('LocationSelected', {'skip': JSON.stringify(foreward)});
    this.router.navigate([this.s6ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/s6/date')]);
  }

  handleFailedIp(fallbackReason: string) {
    this.s6ProcessService.reportTrackingEvent(`IP-detection-failed`, {'reason': fallbackReason});
    this.evaluatingIp = false
    this.s6ProcessService.ipRetrievedSuccessfully = false
  }

  displayCities(cities: PublicMinimalCityData[]) {
    if (cities.length == 0) {
      this.cronosStructureApi.getLocations().then(res => {
        this.matchingCities = res;
      })
    } else {
      this.matchingCities = cities;
    }
  }

  protected readonly onabort = onabort;
}
