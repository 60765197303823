import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SeminarCheckoutS6DatesComponent} from './seminar-checkout-s6-dates/seminar-checkout-s6-dates.component';
import {SeminarCheckoutS6EntryComponent} from './seminar-checkout-s6-entry/seminar-checkout-s6-entry.component';
import {
  SeminarCheckoutS6LocationComponent
} from './seminar-checkout-s6-location/seminar-checkout-s6-location.component';
import {SeminarCheckoutS6MainComponent} from './seminar-checkout-s6-main/seminar-checkout-s6-main.component';
import {SeminarCheckoutS6UserComponent} from './seminar-checkout-s6-user/seminar-checkout-s6-user.component';
import {RouterModule, Routes} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {HidInteractionsModule} from "../../../hid-interactions/hid-interactions.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ShareModule} from "../../../share/share.module";
import {GlobalModule} from "../../../global/global.module";
import {NgxJsonLdModule} from "ngx-json-ld";
import {ActionLinkModule} from "../../../actionLinks/action-link/action-link.module";
import {FeedbackStarsModule} from "../../../feedback-stars/feedback-stars.module";
import {AccountModule} from "../../../account/account.module";
import {DiscountsModule} from "../../../discounts/discounts.module";
import {MatButtonModule} from "@angular/material/button";
import {
  GlobalCheckoutComponentsModule
} from "../../components/global-checkout-components/global-checkout-components.module";

const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/seminars/s6/entry', component: SeminarCheckoutS6EntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/seminars/s6', component: SeminarCheckoutS6MainComponent,
    children: [
      {path: 'location', component: SeminarCheckoutS6LocationComponent},
      {path: 'date', component: SeminarCheckoutS6DatesComponent},
      {path: 'user', component: SeminarCheckoutS6UserComponent}
    ]
  }
]

@NgModule({
  declarations: [
    SeminarCheckoutS6DatesComponent,
    SeminarCheckoutS6EntryComponent,
    SeminarCheckoutS6LocationComponent,
    SeminarCheckoutS6MainComponent,
    SeminarCheckoutS6UserComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    BrowserModule,
    FlexModule,
    HidInteractionsModule,
    MatPaginatorModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    GlobalModule,
    NgxJsonLdModule,
    ActionLinkModule,
    FeedbackStarsModule,
    AccountModule,
    DiscountsModule,
    MatButtonModule,
    GlobalCheckoutComponentsModule,
  ]
})
export class SeminarCheckoutS6Module { }
