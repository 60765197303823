<a fxLayout="column" fxLayoutAlign="space-between center"
   class="image-hover offer-panel pointer "
   routerLink="/bafoeg">
  <div class="image-wrap">
    <img id="offer-image" alt="{{altText}}" #image src="../../../assets/img/bafoeg/bafög-Rechner-Angebot.jpg">
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between start" class="description" >
    <div class="title">Lass dir deinen BAföG-Anteil Online berechnen</div>
    <div class="rating" fxLayout="row" fxLayoutAlign="start center">
      <span class="review-summary" style="margin-left: 0">
        Neu<span *ngIf="!mediaService.isMobile()">es Angebot</span>!
      </span>
    </div>
  </div>

</a>
